import React, {useEffect} from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';

import {getBrText} from '../helpers/helper.rendering'

import { Link } from "gatsby";
import { graphql, useStaticQuery } from 'gatsby';


const Investors = () => {

    const { realEstate } = useStaticQuery(graphql`
            query {
                realEstate {
                    sections {
                        home {
                        investors {
                            hidden
                            title
                            paragraph
                            button {
                              link
                              value
                            }
                            items
                          }
                        }
                    }
                    design {
                        icons
                    }
                }
    }`)

    useEffect(() => {
        AOS.init({ duration : 2000});
    }, [])
    
    const { design: { icons } } = realEstate
    const iconsStyle = icons === 'Solid' || icons === 'Light' ? `-${icons.toLowerCase()}` : ''

    return realEstate.sections && (
        !realEstate.sections.home.investors.hidden  ? (
        <section className="investors">
            <div className="container-fluid">
                <div className="row d-flex align-items-start">
                    <div className="col-lg-2">
                        <p className="section-name">{realEstate.sections.home.investors.title}</p>
                    </div>
                    <div className="col-lg-10">
                        <h2 className="sub-section">
                            {getBrText(realEstate.sections.home.investors.paragraph).map(element => (
                                <>
                                    {element}<br className="d-none d-lg-block"/>
                                </>
                            ))}
                        </h2>
                    </div>
                    <div className="col-10 offset-lg-2">
                        <Link data-aos="fade-in" to={realEstate.sections.home.investors.button.link} className="btn btn-blue">{realEstate.sections.home.investors.button.value}</Link>
                    </div>
                    <div className="col-lg-10 mt-lg-5 offset-lg-2">
                        <div className="row items">
                            {/* data-aos="fade-right" */}
                            <div data-aos="fade-right" className="col-9 col-lg-6 d-flex align-items-start">
                                <i className={`icon-building${iconsStyle}`}></i>
                                <p>{realEstate.sections.home.investors.items[0]}</p>
                            </div>
                            {/* data-aos="fade-left" */}
                            <div data-aos="fade-left" className="col-9 col-lg-6 offset-3 offset-lg-0 d-flex align-items-start">
                                <i className={`icon-investment${iconsStyle}`}></i>
                                <p>{realEstate.sections.home.investors.items[1]}</p>
                            </div>
                            {/* data-aos="fade-right"  */}
                            <div data-aos="fade-right" className="col-9 col-lg-6 d-flex align-items-start">
                                <i className={`icon-hand-shake${iconsStyle}`}></i>
                                <p>{realEstate.sections.home.investors.items[2]}</p>
                            </div>
                            {/* data-aos="fade-left"  */}
                            <div data-aos="fade-left" className="col-9 col-lg-6 offset-3 offset-lg-0 d-flex align-items-start">
                                <i className={`icon-note${iconsStyle}`}></i>
                                <p>{realEstate.sections.home.investors.items[3]}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        ):''
    )
}

export default Investors;